// extracted by mini-css-extract-plugin
var _1 = "hsl(266,67%,88%)";
var _2 = "hsl(270,100%,97%)";
var _3 = "hsl(173,68%,16%)";
var _4 = "hsl(255,9%,26%)";
var _5 = "w5ctk-MQi7MC";
var _6 = "NDWwYiXqZcMG";
var _7 = "_8rFCHIKHhjDv";
var _8 = "gQ8CD0GZviwW";
var _9 = "Y6VuNA3Bpwu2";
var _a = "LC6e2cEyIJVq";
var _b = "wgpD-213da0G";
export { _1 as "dayBackLightHSL", _2 as "dayBackLighterHSL", _3 as "dayForeBrandHSL", _4 as "dayForeDarkHSL", _5 as "for-desktop", _6 as "for-mobile", _7 as "main", _8 as "navigation-bar", _9 as "navigation-link", _a as "option", _b as "select-lining" }
